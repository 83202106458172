.qualification__container {
    max-width: 768px;
}

.qualification__tabs {
    display: flex;
    justify-content: center;
    margin-bottom: var(--mb-2);
}

.qualification__button {
    font-size: var(--h3-font-size);
    font-weight: var(--font-medium);
    color: var(--title-color);
    margin: 0 var(--mb-1);
    cursor: pointer;
}

.qualification__button:hover {
    color: var(--title-color-dark);
}

.qualification__icon {
    font-size: 1.8rem;
    margin-right: var(--mb-0-25);
}

.qualification__active {
    color: var(--title-color-dark);
}

.qualification__sections {
    display: grid;
    grid-template-columns: 0.5fr;
    justify-content: center;
}

.qualification__content{
    display: none;
}

.qualification__content-active {
    display: block;
}

.qualification__data {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    column-gap: 1.5rem;
}

.qualification__title {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
}

.qualification__subtitle {
    display: inline-block;
    font-size: var(--small-font-size);
    margin-bottom: var(--mb-1);
}

.qualification__calendar  {
    font-size: var(--small-font-size);
}

.qualification__rounder {
    display: inline-block;
    width: 13px;
    height: 13px;
    background-color: var(--text-color);
    border-radius: 50%;
}

.qualification__line {
    display: block;
    width: 1px;
    height: 100%;
    background-color: var(--text-color);
    transform: translate(6px, -7px);
}

.qualification__button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: var(--mb-2);
}

.rotate span {
    display: inline-flex;
    animation: rotate 3s infinite;
}
@keyframes rotate {
    0%,
    75% {
        transform: rotateY(360deg);
    }
}
.nine span:nth-of-type(1) {
    animation-delay: 0.2s;
}
.nine span:nth-of-type(2) {
    animation-delay: 0.4s;
}
.nine span:nth-of-type(3) {
    animation-delay: 0.6s;
}
.nine span:nth-of-type(4) {
    animation-delay: 0.8s;
}
.nine span:nth-of-type(5) {
    animation-delay: 1s;
}
.nine span:nth-of-type(6) {
    animation-delay: 1.2s;
}
.nine span:nth-of-type(7) {
    animation-delay: 1.4s;
}
.nine span:nth-of-type(8) {
    animation-delay: 1.6s;
}
.nine span:nth-of-type(9) {
    animation-delay: 1.8s;
}
.nine span:nth-of-type(10) {
    animation-delay: 2.0s;
}
.nine span:nth-of-type(11) {
    animation-delay: 2.2s;
}
.nine span:nth-of-type(12) {
    animation-delay: 2.4s;
}
.nine span:nth-of-type(13) {
    animation-delay: 2.6s;
}
.nine span:nth-of-type(14) {
    animation-delay: 2.8s;
}

@keyframes bounce {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-4px);
    }
}

.bounce {
    display: inline-block;
    animation: bounce 1s infinite;
}

.button:hover .button__icon__down {
    animation: moveDown 0.8s ease-in-out 2;
}

.button:hover .button__icon__up {
    animation: moveUp 0.8s ease-in-out 2;
}

.button:active .button__icon {
    transform: translate(0, 0);
}

@keyframes moveDown {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(0px, -6px);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes moveUp {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(0px, 6px);
    }
    100% {
        transform: translate(0, 0);
    }
}

/* Breakpoints */
/* For large devices */
@media screen and (max-width: 992px) {
    .qualification__container {
        margin-left: auto;
        margin-right: auto;
    }
  }
  
  /* For medium devices */
  @media screen and (max-width: 768px) {
    .qualification__container {
        margin-left: var(--mb-1-5);
        margin-right: var(--mb-1-5);
    }
  }
  
  @media screen and (max-width: 576px) {
    .qualification__sections {
        grid-template-columns: initial;
    }

    .qualification__button {
        margin: 0 var(--mb-0-75);
    }
  }
  
  /* For small devices */
  @media screen and (max-width: 350px) {
    .qualification__data {
        gap: 0.5rem;
    }
  }