.header {
    width: 100%;
    position: fixed; /* sets how an element is positioned in a document */
    top: 0;
    left: 0;
    z-index: var(--z-fixed);
    background-color: var(--body-color);
}

.nav {
    /* perform calculations when specifying CSS property values */
    height: calc(var(--header-height) + 1.5rem);
    /* sets whether an element is treated as a block or 
    inline element and the layout used for its children, 
    such as flow layout, grid or flex. */
    display: flex;
    /* 'justify-content' defines how the browser distributes space between and around content items */
    justify-content: space-between;
    align-items: center;
    /* sets the size of the gap (gutter) between an element's columns. */
    column-gap: 1rem;
}


.nav__logo,
.nav__toggle {
    color: var(--title-color);
    font-weight: var(--font-medium);
}

.nav__list {
    display: flex;
    column-gap: 2rem;
}

.nav__logo-img {
    width: 117px;
    height: 35px;
}

.nav__link {
    display: flex;
    flex-direction: column; /* sets how flex items are placed in the flex container */
    align-items: center;
    font-size: var(--small-font-size);
    color:var(--title-color);
    font-weight: var(--font-medium);
    transition: .3s; /* control animation speed when changing CSS properties */
}

.nav__icon,
.nav__close,
.nav__toggle {
    display: none;
}

/* Active link*/
.active-link,
.nav__link:hover {
    color: var(--title-color-dark);
}

/* Change background header */
.scroll-header {
    box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
}

/* Breakpoints */
/* For medium devices */
@media screen and (max-width: 768px) { 
    .header {
        top: initial; /* 'initial' set an element's CSS property to its default value */
        bottom: 0;
    }

    .nav {
        height: var(--header-height);
    }

    .nav__menu {
        position: fixed;
        bottom: -100%;
        left: 0;
        width: 100%;
        background-color: var(--body-color);

        /* a margin is the space around an element's border, 
        while padding is the space between an element's border and the element's content */
        padding: 2rem 1.5rem 4rem; /* top | left and right | bottom */

        box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15); /* offset-x | offset-y | blur-radius | color */
        border-radius: 1.5rem 1.5rem 0 0; /* top-left | top-right | bottom-right | bottom-left */
        transition: .3s;
    }

    /* Show Menu */
    .show-menu {
        bottom: 0;
    }

    .nav__list {
        display: grid;

        /* 'grid-template-columns' defines the line names and track sizing functions of the grid columns. */
        grid-template-columns: repeat(3, 1fr); /* 'fr' : fraction */
        /* 'repeat' represents a repeated fragment of the track list, 
        allowing a large number of columns or rows that exhibit a recurring pattern 
        to be written in a more compact form. */

        
        gap: 2rem; /* sets the gaps (gutters) between rows and columns */
    }

    .nav__icon{
        font-size: 1.2rem;
    }

    .nav__close {
        position: absolute;
        right: 1.3rem;
        bottom: .5rem;
        font-size: 1.5rem;
        cursor: pointer;
        color: var(--title-color);
    }

    .nav__close:hover {
        color: var(--title-color-dark);
    }

    .nav__toggle {
        font-size: 1.1rem;
        cursor: pointer;
    }

    .nav__icon,
    .nav__close,
    .nav__toggle {
        display: block;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
    .nav__menu {
        padding: 2rem 0.25rem 4rem;
    }

    .nav__list {
        column-gap: 0;
    }
}