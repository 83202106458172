.footer {
    background-color: var(--container-color);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.footer__container {
    padding: 2rem 0 6rem;
}

.footer__title,
.footer__link {
    color: var(--title-color);
}

.footer__title {
    text-align: center;
    margin-bottom: var(--mb-2);
}

.footer__link:hover {
    color: var(--title-color-dark);
}

.footer__list {
    display: flex;
    justify-content: center;
    column-gap: 1.5rem;
    margin-bottom: var(--mb-2);
}

.footer__social {
    display: flex;
    justify-content: center;
    column-gap: 1.25rem;
}

.footer__social-link {
    background-color: var(--title-color);
    color: var(--container-color);
    font-size: 1.25rem;
    padding: 0.4rem;
    border-radius: 0.5rem;
    display: inline-flex;
}

.footer__social-link:hover {
    background-color: var(--title-color-dark);
}

.footer__copy {
    display: block;
    margin-top: 2.5rem;
    color: var(--title-color);
    text-align: center;
    font-size: var(--smaller-font-size);
}

.bookshelf {
    width: 100%;
    height: 128px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
.bookshelf-png {
width: 128px;
height: 128px;
}

/* SMOKE */
#smoke-1 {
    stroke-dasharray: 0, 10;
    animation: smoke 2s ease infinite;
}

#smoke-2 {
    stroke-dasharray: 0, 10;
    animation: smoke 2s 0.5s ease infinite;
}

@keyframes smoke {
    0% { stroke-dasharray: 0, 10; }
    50% { stroke-dasharray: 10, 0; }
    100% { stroke-dasharray: 10, 0; opacity: 0; }
}

/* WRITING */
#line-1 {
    opacity: 0;
    animation: writing 0.5s linear forwards;
}

#line-2 {
    opacity: 0;
    animation: writing 0.5s 1s linear forwards;
}

#line-3 {
    opacity: 0;
    animation: writing 0.5s 1.5s linear forwards;
}

#line-4 {
    opacity: 0;
    animation: writing 0.5s 2s linear forwards;
}

@keyframes writing {
    0% { width: 0px; opacity: 1;}
    100% { width: 14px; opacity: 1;}
}

/* Breakpoints */
/* For large devices */
@media screen and (max-width: 992px) {
    .footer__social-link {
        padding: 0.25rem;
        border-radius:0.25rem;
        font-size: 1rem;
    }
  }