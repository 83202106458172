:root {
	--duration: 10s;
	--size: 80vw;
	--characters: 14;
	--font-size: 22px;
	--delay-character: 0.2s;
	--text-color-hover: #ff8489;
}

$assetPath: "../../assets";

.frame {
	width: var(--size);
	height: var(--size);
	max-width: 400px;
	max-height: 500px;
	margin: 0 auto;
	border: 1px #fbfbfb solid;
	border-radius: 15px;
	position: relative;
	box-shadow: inset 0 0 0 13px #4c4c4c;
	background: url(#{$assetPath}/about.jpg)
		no-repeat center;
	background-size: cover;
	// transform: rotateY(180deg);
	transition: ease all 0.3s;
	&:hover {
		box-shadow: inset 0 0 0 0 white;
		border: 1px #f9f9f9 solid;
		cursor: pointer;
		.text {
			mix-blend-mode: difference;
			span {
				color: var(--text-color-hover);
			}
		}
	}
	.text {
		transform: translate(
			calc(calc(var(--font-size) * -1) / 2),
			calc(calc(var(--font-size) * -1) / 2)
		);
		position: relative;
		height: 100%;
		transition: ease all 0.3s;
	}
	span {
		font-size: var(--font-size);
		display: inline-block;
		text-align: center;
		width: var(--font-size);
		animation: frameMove var(--duration) linear infinite;
		position: absolute;
		transition: ease all 0.3s;
		@for $i from 1 through 52 {
			&:nth-of-type(#{$i}) {
				animation-delay: calc(var(--delay-character) * #{$i});
			}
		}
	}
}
@keyframes frameMove {
	0% {
		top: 0;
		left: 0;
		transform: rotate(0deg);
	}
	24% {
		top: 0;
		left: 100%;
		transform: rotate(0deg);
	}
	25% {
		transform: rotate(90deg);
	}
	49% {
		top: 100%;
		left: 100%;
		transform: rotate(90deg);
	}
	50% {
		transform: rotate(180deg);
	}
	74% {
		top: 100%;
		left: 0%;
		transform: rotate(180deg);
	}
	75% {
		transform: rotate(270deg);
	}
	99% {
		top: 0%;
		left: 0%;
		transform: rotate(270deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
