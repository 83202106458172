.home__container{
    row-gap: 7rem;
}

.home__content {
    grid-template-columns: 116px repeat(2, 1fr);
    padding-top: 0.5rem;
    column-gap: 2rem;
    align-items: center;
}

.home__social {
    display: grid;
    grid-template-columns: max-content;
    row-gap: 1rem;
}

.home__social-icon {
    font-size: 1.25rem;
    color: var(--title-color);
}

.home__social-icon:hover {
    color: var(--title-color-dark);
}

.home__title {
    font-size: calc(var(--big-font-size) - 0.5rem);
    margin-bottom: var(--mb-0-25);
}

.home__title .signature {
    padding-top: 1rem;
    padding-left: 0.7rem;
}

.home__hand {
    width: 38px;
    height: 38px;
    margin-left: 0.4rem;
}

.home__subtitle {
    position: relative;
    font-size: var(--h3-font-size);
    padding-left: 5.4rem;
    font-weight: var(--font-normal);
    margin-bottom: var(--mb-1);
}

.home__subtitle::before {
    content: '';
    position: absolute;
    width: 70px;
    height: 1px;
    background-color: var(--text-color);
    left: 0;
    top: 1rem;
}

.home__description{
    max-width: 400px;
    margin-bottom: var(--mb-2);
}

.home__img-container {
    align-items: center;
    justify-content: center;
    grid-template-rows: 100px repeat(1, 1fr);
    margin-right: 4rem;
}

.home__barcode {
    background: url(../../assets/signature.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 192px;
    height: 72px;
    display: grid;
    grid-template-rows: max-content;
    margin-top: 1.5rem;
    margin-left: 4rem;
}

.home__img {
    background: url(../../assets/me.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: inset 0 0 0 12px rgba(73, 68, 88, 0.25);
    order: 1;
    justify-self: center;
    width: 320px;
    height: 320px;
    animation: profile__animate 4s ease-in-out infinite;
    transform: rotateY(0deg);
    transition: transform 3s ease-in-out;
    cursor: pointer;
    display: grid;
    grid-template-rows: max-content;
}

.home__img > div {
    backface-visibility: visible;
    background-size: cover;
    position: absolute;
}

.home__img.flipped {
    transform: rotateY(540deg);
}

.home__justify{
    text-align: justify;
}

.home__italic-text {
    font-style: italic;
}

.blinking-cursor::after {
    content: "|";
    animation: blink 1s step-end infinite;
}

@keyframes blink {
from,
to {
    opacity: 1;
}
50% {
    opacity: 0;
}
}

.keyword {
    font-weight: bold;
}

@keyframes profile__animate {
    0% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%; 
    }

    50% {
        border-radius: 45% 75% 85% 55%/65% 75% 45% 75%;
    }

    100% {
        border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;  
    }
}

.home__scroll {
    margin-left: 9.25rem;
}

.wheel {
    animation: scroll 2s ease infinite;
}

@keyframes scroll {
    0% {
        transform: translateY(0);
    }

    30% {
        transform: translateY(3.75rem);
    }
}

.home__scroll-name {
    color: var(--title-color);
    font-weight: var(--font-medium);
    margin-right: var(--mb-0-25);
    margin-left: 2px;

}

.home__scroll-arrow {
    font-size: 1.25rem;
    color: var(--title-color);
}

/* Breakpoints */
/* For large devices */
@media screen and (max-width: 992px) {
    .home__content{
      grid-template-columns: 100px repeat(2, 1fr);
      column-gap: 1.25rem;
    }

    .home__hand {
        width: 26px;
        height: 26px;
    }

    .home__subtitle {
        padding-left: 3.75rem;
        margin-bottom: var(--mb-1);
    }

    .home__subtitle::before {
        width: 42px;
        top: 0.8rem;
    }

    .home__description {
        max-width: initial;
        margin-bottom: var(--mb-2-5);
    }

    .home__img {
        width: 250px;
        height: 250px;
        box-shadow: inset 0 0 0 9px rgb(255 255 255 / 30%);
    }

    .home__barcode {
        width: 120px;
        height: 60px;
        margin-top: 0.5rem;
        margin-left: 2rem;
    }

    .home__img-container {
        margin-left: 1rem;
    }

    .home__scroll {
        margin-left: 7.5rem;
    }

  }
  
  /* For medium devices */
  @media screen and (max-width: 768px) {
    .home__content {
        grid-template-columns: 0.5fr 3fr;
        padding-top: 3.5rem;
    }

    .home__img {
        order: initial;
        justify-self: initial;
    }

    .home__data {
        grid-column: 1/3;
    }

    .home__img {
        box-shadow: inset 0 0 0 6px rgb(255 255 255 / 30%);
        width: 200px;
        height: 200px;
    }


    .home__barcode {
        width: 120px;
        height: 60px;
        margin-top: 0.5rem;
        margin-left: 2rem;
    }

    .home__img-container {
        margin-left: 2.5rem;
    }

    .home__scroll {
        display: none;
    }
  }
  
  /* For small devices */
  @media screen and (max-width: 350px) {
    .home__img{
        width: 180px;
        height: 180px;
    }

    .home__hand {
        width: 22px;
        height: 22px;
    }

    .home__img-container {
        margin-left: 2.5rem;
    }
  }