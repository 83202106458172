.no-scroll {
    overflow: hidden;
}

.about__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    column-gap: 4rem;
}

.about__img {
    width: 350px;
    border-radius: 1.5rem;
    justify-self: center;
}

.about__info {
    grid-template-columns: repeat(3, 140px);
    gap: 0.5rem;
    margin-bottom: var(--mb-2);
}

.about__box {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.75rem;
    text-align: center;
    padding: 1rem 1.25rem;
}

.about__icon {
    font-size: 1.5rem;
    color: var(--title-color);
    margin-bottom: var(--mb-0-5);
}

.about__title {
    font-size: var(--small-font-size);
    font-weight: var(--font-medium);
}

.about__subtitle {
    font-size: var(--tiny-font-size);
}

.about__description{
    padding: 0 2rem 0 0;
    margin-bottom: var(--mb-2-5);
}

.about__justify{
    text-align: justify
}

.about__remarks{
    margin-top: 5px;
    padding-left: 10px;
    font-size: var(--small-font-size);
    text-decoration: underline;
    cursor: pointer;
}

.about__modal-content{
    width: 500px;
    position: relative;
    background-color: var(--container-color);
    padding: 0 1.5rem 0;
    border-radius: 1.5rem;
}

.about__modal-close {
    position: absolute;
    top: 3%;
    left: 63%;
    font-size: 1.5rem;
    color: var(--title-color);
    cursor: pointer;
    z-index: 100;
}

.active-modal {
    opacity: 1;
    visibility: visible;
}

.about__modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; 
    background-color: rgba(0, 0, 0, 0.5);
    z-index: var(--z-modal);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    opacity: 0;
    visibility: hidden;
    transition: .3s;
}

/* Breakpoints */
/* For large devices */
@media screen and (max-width: 992px) {
    .about__container {
        grid-template-columns: 1fr;
        row-gap: 2.5rem;
    }

    .about__img {
        width: 220px;
    }

    .about__box {
        padding: 0.75rem 0.5rem;
    }

    .about__data {
        text-align: center;
    }

    .about__info {
        justify-content: center;
    }

    .about__description {
        padding: 0 5rem;
        margin-bottom: 2rem;


    }
}
  
/* For medium devices */
@media screen and (max-width: 576px) {
    .about__info {
        grid-template-columns: repeat(3, 1fr);
    }

    .about__description {
        padding: 0;
    }
    .about__modal-close {
        top: 13%;
        right: -13%;
    }
}
  
/* For small devices */
@media screen and (max-width: 350px) {
    .about__info {
        grid-template-columns: repeat(2, 1fr);
    }
}
