.notfound__container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 85vh;
}

.notfound__content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 500px;
}

.notfound__title {
    font-size: 9rem;
    font-weight: var(--font-semi-bold);
    color: var(--title-color);
    line-height: 1;
    margin-bottom: var(--mb-1);
}

.notfound__subtitle {
    font-size: var(--h1-font-size);
    font-weight: var(--font-medium);
    color: var(--title-color);
    margin-bottom: var(--mb-1-5);
}

.notfound__description {
    font-size: var(--normal-font-size);
    margin-bottom: var(--mb-3);
    color: var(--text-color);
}

.notfound__button {
    display: inline-block;
    background-color: var(--title-color);
    color: var(--container-color);
    padding: 1.25rem 2rem;
    border-radius: 1rem;
    font-weight: var(--font-medium);
    transition: 0.3s;
}

.notfound__button:hover {
    background-color: var(--title-color-dark);
}

/* Responsive styles */
@media screen and (max-width: 768px) {
    .notfound__title {
        font-size: 7rem;
    }
    
    .notfound__subtitle {
        font-size: var(--h2-font-size);
    }
}

@media screen and (max-width: 350px) {
    .notfound__title {
        font-size: 5rem;
    }
    
    .notfound__button {
        padding: 1rem 1.5rem;
    }
} 