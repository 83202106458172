:root {
	--background: #f3a683;
	--base: #786fa6;
	--accent: #303952;
	--shadow: #e77f67;
}


.cool {

	span {
		color: var(--shadow);
		display: inline-block;
		position: relative;
		// make the font black again if no animation is playing
		// @media (prefers-reduced-motion) {
		// 	color: var(--base);
		// }

		// the magic, kinda
		&::before {
			animation-name: max-height; 
			animation-duration: 0.85s; 
			animation-timing-function: cubic-bezier(0.61, 1, 0.88, 1); // key function
			animation-delay: 0.4s;
			animation-iteration-count: 3; 
			animation-direction: alternate;
			animation-fill-mode: both;
			color: var(--accent);
		}
		
		&::after {
			animation-name: max-width; 
			animation-duration: 0.85s; 
			animation-timing-function: cubic-bezier(0.61, 1, 0.88, 1); // key function
			animation-delay: 0.4s;
			animation-iteration-count: 2; 
			animation-direction: alternate;
			animation-fill-mode: both;
			color: var(--base);
		}
		
		&::before,
		&::after {
			// Using data attribute to avoid duplicated content in HTML. It has a very good support when used in the content property https://caniuse.com/mdn-css_types_attr
			content: attr(data-text);
			left: 0;
			// The overflowing text is cut off
			overflow: hidden;
			position: absolute;
			// Set the speak property to none, because we don't want screen readers to read every word in the paragraph twice. "Hello, hello"? No!
			speak-as: none;

			// In this case we just don't animate things, BUT we can maybe animate opacity only since reduced motion != no motion
			// @media (prefers-reduced-motion) {
			// 	animation: none;
			// 	content: "";
			// }
		}
	}
}

@keyframes max-width {
	from {
		max-width: 0;
	}
	to {
		max-width: 100%;
	}
}

@keyframes max-height {
	from {
		max-height: 0;
	}
	to {
		max-height: 100%;
	}
}