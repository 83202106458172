/* Google Fonts*/
/* can import url / files */
/* @import "navigation.css";
/* @import url("navigation.css"); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

/* Variable CSS*/
/*':root' matches the root element of a tree representing the document */
:root {
  /* represents the font size of the root element */
  /*.5rem = 8px | 1rem = 16px ...*/
  --header-height: 3rem; /* rem stands for “root em” */

  /* Colors*/
  /* Color mode HSL(hue, saturation, lightness)*/
  /* '--' Declares a custom property */
  /* 'var()' access the custom property */
  --hue: 0;
  --sat: 0%;
  --title-color: hsl(var(--hue), var(--sat), 20%);
  --title-color-dark: hsl(var(--hue), var(--sat), 0%);
  --text-color: hsl(var(--hue), var(--sat), 46%);
  --body-color: hsl(var(--hue), var(--sat), 98%);
  --container-color: #fff;

  /* Font and typography*/
  --body-font: "Poppins", sans-serif; /* Font Name & Font Families */
  
  /*.5rem = 8px | 1rem = 16px ...*/
  --big-font-size: 3.5rem;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;
  --tiny-font-size: 0.625rem;

  /* Font weight*/
  /* thin (100), normal (400), bold (700), and heavy (900) */
  --font-normal: 400;
  --font-medium: 500;
  --font-semi-bold: 600;

  /* Margins Bottom*/
  /*.5rem = 8px | 1rem = 16px ... */
  --mb-0-25: 0.25rem;
  --mb-0-5: 0.5rem;
  --mb-0-75: 0.75rem;
  --mb-1: 1rem;
  --mb-1-5: 1.5rem;
  --mb-2: 2rem;
  --mb-2-5: 2.5rem;
  --mb-3: 3rem;
  
  /* z index */
  /* Overlapping elements with a larger z-index cover those with a smaller one */
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}

/* Responsive typography */
/* apply part of a style sheet based on the result of one or more media queries */
@media screen and (max-width: 992px) {
  :root {
    --big-font-size: 2.75rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
  }
}

/* Base */
/* Selects all elements */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; /* include the padding and border in an element's total width and height */
}

html {
  scroll-behavior: smooth; /* smoothly animate the scroll position*/
}

body,
button,
input,
textarea {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body {
  background-color: var(--body-color);
  color: var(--text-color);
}

h1, h2, h3 {
  color: var(--title-color);
  font-weight: var(var(--font-semi-bold));
}

ul {
  list-style: none; /* Set different list item markers for lists */
}

a {
  text-decoration: none; /* sets the appearance of decorative lines on text */
}

button {
    /*  sets the mouse cursor, if any, 
  to show when the mouse pointer is over an element.*/
  cursor: pointer; 

  /* An outline is a line that is drawn just outside the border edge of an element. 
  Unlike borders, outlines don't take up space or affect surrounding elements */
  border: none;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* Reusable CSS Classes */
.section {
  padding: 6rem 0 2rem;
}

.section__title {
  font-size: var(--h1-font-size);
  color: var(--title-color);
}

.section__subtitle {
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: 4rem;
}

.section__title,
.section__subtitle {
  text-align: center;
}

/* Layout */
.container {
  max-width: 968px;
  margin-left: auto;
  margin-right: auto;
}

.grid {
  display: grid;
  gap: 1.5rem; /* sets the gaps (gutters) between rows and columns. */
}

/* Buttons */
.button {
  display: inline-block;
  background-color: var(--title-color);
  color: var(--container-color);
  padding: 1.25rem 2rem;
  border-radius: 1rem;
  font-weight: var(--font-medium);
  position: relative;
}

.button:hover{
  background-color: var(--title-color-dark);
}

.button__icon {
  margin-left: var(--mb-0-5);
}

.button--flex{
  display: inline-flex;
  align-items: center;
}

.popUpImg-left {
  display: none;
  position: absolute;
  right: 100%;
  margin-right: 10px; /* Adjust this value to control the distance between text and image */
  width: 150%; /* Adjust the width of the image as needed */
  justify-content: center;
  align-items: center;
  padding: 2 0rem;
  transition: .3s;
  background-color: var(--container-color);
  border-radius: 1rem;
}

.popUpImg-right {
  display: none;
  position: absolute;
  left: 100%;
  margin-left: 10px; /* Adjust this value to control the distance between text and image */
  width: 150%; /* Adjust the width of the image as needed */
  justify-content: center;
  align-items: center;
  padding: 2 0rem;
  transition: .3s;
  background-color: var(--container-color);
  border-radius: 1rem;
}

.hover-text:hover + .popUpImg-left {
  display: block;
}

.hover-text:hover + .popUpImg-right {
  display: block;
}

/* Breakpoints */
/* For large devices */
@media screen and (max-width: 992px) {
  .container{
    margin-left: var(--mb-1-5);
    margin-right: var(--mb-1-5);
  }

  .button {
    padding: 1rem 1.75rem;
  }

  .button__icon {
    width: 22px;
    height: 22px;
  }
}

.saber-toasts-containers {
  position: fixed;
  width: 320px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  direction: rtl;
}

.saber-toasts-container-bottom-right {
  right: 1.25rem;
  bottom: 1.25rem;
}

.saber-toasts-container-top-right {
  right: 1.25rem;
  top: 5.25rem;
  flex-direction: column-reverse;
  z-index: 1000;
}

.saber-toasts-container-bottom-left {
  left: 1.25rem;
  bottom: 1.25rem;
}

.saber-toasts-container-top-left {
  left: 1.25rem;
  top: 1.25rem;
  flex-direction: column-reverse;
}

.saber-toast {
  width: 320px;
  height: 80px;
  background-color: #FFF;
  box-shadow: 0px 0px 10px #0000001A;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  display: flex;
  align-items: stretch;
  gap: 12px;
}

.saber-toast .right-border {
  width: 6px;
  border-radius: 0px 10px 10px 0px;
  -webkit-border-radius: 0px 10px 10px 0px;
  -moz-border-radius: 0px 10px 10px 0px;
  -ms-border-radius: 0px 10px 10px 0px;
  -o-border-radius: 0px 10px 10px 0px;
  margin: 0 0 0 2px;
}

.saber-toast .state-icon-holder {
  width: 40px;
  display: grid;
  place-items: center;
}

.saber-toast .state-icon {
  width: 40px;
  height: 40px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.saber-toast .state-icon svg {
  width: 50%;
}

.saber-toast .text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;
  flex: 1;
}

.saber-toast .title {
  font-size: 12px;
}

.saber-toast .text {
  font-size: 13px;
  color: #18181A;
  font-weight: bold;
}

.saber-toast .close-icon-holder {
  padding: 12px;
}

.saber-toast .close-icon {
  width: 22px;
  height: 22px;
  background-color: #F1F1F1;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: grid;
  place-items: center;
  cursor: pointer;
}

.saber-toast .close-icon svg {
  width: 70%;
  color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 520px) {

}

/* For medium devices */
@media screen and (max-width: 768px) {
  .saber-toasts-container-top-right {
    right: 1.25rem;
    top: 1.25rem;
    flex-direction: column-reverse;
    z-index: 1000;
  }
  body {
    margin: 0 0 var(--header-height) 0;
  }
  
  .section {
    padding: 2rem 0 4rem;
  }

  .section__subtitle {
    margin-bottom: var(--mb-3);
  }
  .hover-text:hover + .popUpImg-left {
    display: none;
  }
  
  .hover-text:hover + .popUpImg-right {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .saber-toasts-containers {
    width: 240px;
  }

  .saber-toast {
      width: 240px;
      height: 80px;
      gap: 8px;
  }

  .saber-toast .state-icon-holder {
      width: 30px;
  }

  .saber-toast .state-icon {
      width: 30px;
      height: 30px;
  }

  .saber-toast .title {
      font-size: 11px;
  }

  .saber-toast .text {
      font-size: 12px;
  }

  .saber-toast .text-container {
      gap: 3px;
  }

  .saber-toast .close-icon-holder {
      padding: 8px;
  }

  .saber-toast .close-icon {
      width: 18px;
      height: 18px;
  }
  .saber-toasts-container-top-right {
    right: 1.25rem;
    top: 0.25rem;
    flex-direction: column-reverse;
    z-index: 1000;
  }
}

/* For small devices */
@media screen and (max-width: 350px) {
  .saber-toasts-container-top-right {
    right: 1.25rem;
    top: 1.25rem;
    flex-direction: column-reverse;
    z-index: 1000;
  }

  :root {
    --big-font-size: 2.25rem;
  }
  .container {
    margin-left: var(--mb-1);
    margin-right: var(--mb-1);
  }
}