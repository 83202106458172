.box-top-left,
.box-bottom-right {
  --border-size: 4px;
  --border-angle: 0turn;
  background-image: 
    conic-gradient(from var(--border-angle), #fff, #fff 50%, #fff),
    conic-gradient(from var(--border-angle), transparent 50%, #000033, #1D2951);
  background-size: calc(100% - (var(--border-size) * 2)) calc(100% - (var(--border-size) * 2)), cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.box-top-right,
.box-bottom-left {
  --border-size: 4px;
  --border-angle: 0turn;
  background-image: 
    conic-gradient(from var(--border-angle), #fff, #fff 50%, #fff),
    conic-gradient(from var(--border-angle), transparent 50%, #000033, #1D2951);
  background-size: calc(100% - (var(--border-size) * 2)) calc(100% - (var(--border-size) * 2)), cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.box-top-left {
  animation: bg-spin-a 5s linear infinite;
}

.box-top-right {
  animation: bg-spin-b 5s linear infinite;
}

.box-bottom-left {
  animation: bg-spin-c 5s linear infinite;
}

.box-bottom-right {
  animation: bg-spin-d 5s linear infinite;
}

@keyframes bg-spin-a {
  from {
    --border-angle: 0turn; /* Start from 0 degrees (no rotation) */
  }
  to {
    --border-angle: 1turn; /* Rotate 360 degrees (full rotation) */
  }
}

@keyframes bg-spin-b {
  from {
    --border-angle: 0turn; /* Start from 90 degrees */
  }
  to {
    --border-angle: -1turn; /* Rotate to -270 degrees (90 degrees anticlockwise) */
  }
}

@keyframes bg-spin-c {
  from {
    --border-angle: 0.5turn; /* Start from 180 degrees */
  }
  to {
    --border-angle: -0.5turn; /* Rotate to -180 degrees (180 degrees anticlockwise) */
  }
}

@keyframes bg-spin-d {
  from {
    --border-angle: -0.5turn; /* Start from 270 degrees */
  }
  to {
    --border-angle: 0.5turn; /* Rotate to -90 degrees (270 degrees anticlockwise) */
  }
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}
