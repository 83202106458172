.testimonial__container {
    padding: 0px 5px 0px 0px !important;
    width: 750px;
}

.testimonial__card {
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.2 );
    padding: 1.5rem 2rem;
    border-radius: 1.5rem;
    margin-bottom: var(--mb-3);
    aspect-ratio: 16 / 12;
    object-fit: cover;
    box-shadow: 4px 4px 2px rgba(0, 0, 0, 0.3);
}

.testimonial__img {
    width: 60px;
    border-radius: 3rem;
    margin-bottom: var(--mb-1);
    aspect-ratio: 1;
    object-fit: cover;
    box-shadow: 2px 4px 2px rgba(0, 0, 0, 0.3);
}

.testimonial__name {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-0-25);
}

.testimonial__description {
    font-size: var(--small-font-size);
    font-style: italic;
}

.title-animation {
    color: hsl(0, 0%, 28%);
  }
  
.title-animation {
background: linear-gradient(to right, hsl(0, 0%, 30%) 0, hsl(0, 0%, 100%) 10%, hsl(0, 0%, 30%) 20%);
background-clip: text;
-webkit-text-fill-color: transparent;
animation: shine 2s infinite linear;
}
  
.testimonial__justify{
    text-align: justify
}

@keyframes shine {
0% {
    background-position: 0;
}
60% {
    background-position: 600px;
}
100% {
    background-position: 600px;
}
}

/* Swiper Class */
.swiper-pagination-bullet {
    background-color: var(--text-color) !important;
}

.swiper-pagination-bullet-active {
    background-color: var(--title-color) !important;
}

/* Breakpoints */
/* For large devices */
@media screen and (max-width: 992px) {
    .testimonial__container {
        width: initial;
    }

    .testimonial__card {
        padding: 1.25rem 1.5rem;
    }
  }
  
  /* For medium devices */
  @media screen and (max-width: 768px) {

  }
  
  @media screen and (max-width: 576px) {
  }
  
  /* For small devices */
  @media screen and (max-width: 350px) {

  }