.work__filters {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 0.75rem;
    margin-bottom: var(--mb-2);
}

.work__item {
    cursor: pointer;
    color: var(--title-color);
    padding: 0.25rem 0.75rem;
    font-weight: var(--font-medium);
    border-radius:  0.5rem;
    text-transform: capitalize;
}

.work__item:hover {
    background-color: var(--title-color);
    color: var(--container-color);
}

/* New structure styles */
.works__content {
    display: flex;
    flex-direction: column;
}

.work__projects {
    position: relative;
    min-height: 500px; /* Reduced height for the projects area */
    padding-bottom: 70px; /* Add padding at the bottom to prevent overlap */
}

.work__container {
    grid-template-columns: repeat(3, max-content);
    gap: 3rem; /* Reduced gap between items */
    justify-content: center;
    margin-bottom: 20px; /* Add margin to prevent overlap with pagination */
}

.work__card {
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.6px;
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1rem; /* Reduced padding */
}

.work__img {
    border: 1px solid rgba(0, 0, 0, 0.1);
    width: 220px; /* Reduced width from 295px */
    border-radius: 1rem;
    margin-bottom: var(--mb-0-5); /* Reduced margin */
    aspect-ratio: 1;
    object-fit: cover;
}

.work__title {
    font-size: var(--small-font-size); /* Smaller font size */
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-0-25); /* Reduced margin */
}

.work__button {
    color: var(--text-color);
    font-size: var(--smaller-font-size); /* Smaller font size */
    display: flex;
    align-items: center;
    column-gap: 0.25rem;
}

.work__button-icon {
    font-size: 0.85rem; /* Smaller icon */
    transition: 0.4s;
}

.work__button:hover .work__button-icon {
    transform: translateX(0.25rem);
}

/* Active Work */
.active-work {
    background-color: var(--title-color);
    color: var(--container-color);
}

/* Pagination Styles */
.work__pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    position: absolute;
    bottom: 10px; /* Adjust position from bottom */
    left: 0;
    right: 0;
    padding: 10px 0;
    background-color: var(--body-color); /* Match body background */
}

.pagination__placeholder {
    height: 40px; /* Height for placeholder when no pagination is shown */
}

.pagination__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border-radius: 0.5rem;
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 1.25rem;
    cursor: pointer;
    color: var(--title-color);
    transition: 0.3s;
}

.pagination__button:hover {
    background-color: var(--title-color);
    color: var(--container-color);
}

.pagination__button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.pagination__numbers {
    display: flex;
    gap: 0.5rem;
}

.pagination__number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border-radius: 0.5rem;
    background-color: var(--container-color);
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: var(--small-font-size);
    cursor: pointer;
    transition: 0.3s;
}

.pagination__number:hover {
    background-color: var(--title-color);
    color: var(--container-color);
}

.pagination__number-active {
    background-color: var(--title-color);
    color: var(--container-color);
}

/* Responsive styles for pagination */
@media screen and (max-width: 350px) {
    .pagination__numbers {
        gap: 0.25rem;
    }
  
    .pagination__number, 
    .pagination__button {
        width: 1.5rem;
        height: 1.5rem;
    }
  
    .pagination__button {
        font-size: 1rem;
    }
}

@media screen and (max-width: 992px) {
    .work__projects {
        min-height: 650px; /* Adjusted for smaller items */
        padding-bottom: 70px; /* Maintain padding for pagination */
    }
    
    .work__container {
        grid-template-columns: repeat(2, max-content);
        gap: 1.25rem;
    }

    .work__card {
        padding: 0.75rem; /* Smaller padding for mobile */
    }

    .work__img {
        margin-bottom: 0.5rem;
    }

    .work__title {
        margin-bottom: 0.25rem;
    }
}

@media screen and (max-width: 768px) {
    .work__projects {
        min-height: 950px; /* Adjusted for smaller items */
        padding-bottom: 70px; /* Maintain padding for pagination */
    }
    
    .work__container {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 576px) {
    .work__projects {
        min-height: 900px; /* Adjusted for smaller items */
        padding-bottom: 70px; /* Maintain padding for pagination */
    }
    
    .work__container {
        grid-template-columns: 1fr;
    }

    .work__img {
        width: 100%
    }
}
  
@media screen and (max-width: 350px) {
    .work__item {
        font-size: var(--small-font-size);
    }

    .work__filters {
        column-gap: 0.25rem;
    }
}